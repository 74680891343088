<template>
  <Modal :id="id" :modalTitle="modalTitle" :class="classes">
    <p>Are you sure you want to remove this Crypto from the Pack?</p>
    <button class="btn btn-outline-secondary float-left mt-4" data-dismiss="modal" aria-label="Close">Cancel</button>
    <button class="btn btn-primary float-right mt-4" data-dismiss="modal" aria-label="Close" @click="confirm()">Confirm</button>
  </Modal>
</template>

<script>

import Modal from '../../../Shared/Modal.vue';
import axios from 'axios';
export default {
  name: 'DeletePack',
  props: [
    'id',
    'modalTitle',
    'class',
    'idPack',
    'idCrypto'
  ],
  data() {
    return {
      classes: this.class,
    }
  },
  components: {
    Modal
  },
  methods: {
    confirm(){
      axios.delete(`/cryptos/packs/crypto?id_pack=${this.idPack}&id_crypto=${this.idCrypto}`)
        .then(()=> {
          this.$emit('cryptoDeletedFromPack');
        })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
